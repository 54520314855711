// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  app_url : 'https://mcare.mhatkerala.org/api/',
  main_url : 'https://mcare.mhatkerala.org/',
  profile_url : 'https://mcare.mhatkerala.org/uploads/profile_image/',
  file_url : 'https://mcare.mhatkerala.org/uploads/note_file/'
};

 // app_url : 'https://mcare.mhatkerala.org/api/',
  // main_url: 'https://mcare.mhatkerala.org/',

  // app_url: 'https://dev.mhat.dridatah.com/api/', 
  // main_url : 'https://dev.mhat.dridatah.com/',

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
